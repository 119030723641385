/* --------------------------------
   contact form
-------------------------------- */

$gutter: 30px;

.section--contacts
{
	.map-container
	{
		margin-top: 70px;
		margin-bottom: -70px;

		.g_map
		{
			height: 100%;
			width: 100%;
		}

		&--left { left: 0; }
		&--right { right: 0; }
	}
}

.contact-form
{
	margin-left: auto;
	margin-right: auto;
	max-width: 770px;

	.textfield
	{
		&:hover,
		&:focus
		{
			.section--dark-bg & { color: $primary-color; }
		}
	}
}

.contact-bg{
	background-image: url('https://picsum.photos/id/1012/800/800');
	background-repeat: no-repeat;
	background-size: cover;
    height: 100%;
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	.section--contacts
	{
		.map-container
		{
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}